<template>
  <div class="filter">
    <div class="filter-block container flex">
      <ul class="filter__items flex">
        <li class="filter__item search">
          <input
            v-model="inputSearch"
            class="filter__search search-input"
            type="text"
            placeholder="Поиск .."
          />
          <img class="search-icon" src="@/assets/png/search-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <b-form-select
          
            v-model="status"
            class="filter__item-select main__select"
            :options="listStatus"
            text-field="ru"
            value-field="name"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <button class="filter__item-btn" @click="dateShow = !dateShow">
            {{ range.start == null && range.end == null ? 'Дата выезда' : new Date(range.start).toISOString().slice(0, 10) + ' - ' + new Date(range.end).toISOString().slice(0, 10)}}
            <b-icon
              class="filter__item-btn-icon icon-right"
              style="color: #707070; margin-left: 10px"
              icon="calendar4-week"
            />
          </button>
          <div v-show="dateShow" class="sub-header__calendar">
            <vc-date-picker
              v-model="range"
              class="add__calendar"
              color="green"
              :rows="1"
              is-range
            />
            <button class="btn btn-primary mt-2" @click="hideModal()">Ok</button>
            <button class="btn btn-primary mt-2" @click="inputDataClean('')">Очистить</button>
          </div>
        </li>
      </ul>
      <slot name="logbook" class=""></slot>
    </div>
    <div v-if="dateShow" class="main-close" @click="dateShow = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      selected: null,
      range: [],
      status: 'A',
      address: null,
      date: null,
      dateShow: false,
      inputSearch: '',
      listStatus: [
        { name: 'A', ru: 'Статус рейса' },
        {
          slug: "0",
          name: "new",
          ru: "Новый",
          color: "#4D7FD8",
          colorText: "#FFFFFF",
          type: "routes",
        },
        {
          slug: "0-0",
          name: "refuse",
          ru: "Отказ",
          color: "#4D7FD8",
          colorText: "#FFFFFF",
          type: "orders",
        },
        {
          slug: "1",
          name: "approved",
          ru: "Одобрен",
          color: "var(--secondary-color)",
          colorText: "#FFFFFF",
          type: "routes",
        },
      ]
    };
  },
  computed: {
    ...mapState(['routesDrivers']),
  },
  methods: {
    inputDataClean() {
      this.range = [];
      this.$store.commit("dateFilter", null);
      this.hideModal();
    },
    hideModal() {
      this.dateShow = false;
      if(this.status == 'A'){
        this.getRoutesAtDate();
      }else{
        this.getRoutesAtStatusAndDate()
      }

    },
   makeSearch(value){
       this.$store.dispatch('updateRoutesDrivers' ,{
        value : value
       });
          if(this.inputSearch != ''){
            this.$emit('search', {
              value : this.inputSearch
          })
       }
    },
    getRoutesAtStatusAndDate(){
        let start = new Date(this.range.start).toISOString().slice(0, 10);
        let end = new Date(this.range.end).toISOString().slice(0, 10); 
        let status = '';

        switch (this.status) {
          case 'refuse':
            status = 3;
            break;
          case 'approved':
            status = 2;
            break;
          default:
           status = 1
        }
        this.$store.dispatch('updateRoutesDriversByStatusDate', {
          start,
          end,
          status
        }) 
    },

    getRoutesAtDate(){
        let start = new Date(this.range.start).toISOString().slice(0, 10);
        let end = new Date(this.range.end).toISOString().slice(0, 10); 
        this.$store.dispatch('updateRoutesDriversByDate', {
          start,
          end
        }) 
    }
  },
  watch: {
     inputSearch: function(){
      if(this.inputSearch != '')
        this.makeSearch(this.inputSearch);
      else
          this.$emit('getRoutes');
          this.$emit('search', {
          value : this.inputSearch
       })
    },

    status: function(){
      if(!this.range.start && !this.range.end && this.status != 'A'){
        this.makeSearch(this.status);  
      }else if(this.status == 'A'){
        this.$emit('getRoutes');
      }else{
        this.getRoutesAtStatusAndDate();  
      }
    },
  }
};
</script>

<style scoped>
.filter {
  padding: 10px 0 10px 0;
  background-color: #fff;
  border-top: 1px solid #f2f2f8;
  position: relative;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}

.add__calendar {
  position: static;
  background: #fff;
}
.filter__item:not(:last-child) {
  margin-right: 30px;
}
.filter__search {
  max-width: 100%;
  color: #acacac;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 45px;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.filter__search-address {
  font-size: 1rem;
}
.filter__item-select {
  /* background: #FFFFFF; */
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 250px;
}
.select-map {
  width: 180px;
}
.main__select-icon {
  top: 50%;
}
.filter__item-btn {
  height: 100%;
  width: 250px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.filter__item-btn-icon {
  right: 3px;
}

.filter-btn {
  position: relative;
  margin-left: 30px;
  margin-top: 10px;
}

.filter-btn__icon {
  margin-right: 12px;
}

.filter__change {
  width: auto;
  padding-bottom: 15px;
  margin-top: 10px;
}

.filter__change-text {
  margin: 0px 12px;
}

.filter-label {
  background: var(--secondary-color);
}

.filter-label__defaul {
  background: #83b1f4;
}
</style>
